import React from 'react'

import styles from './styles.module.scss'
import VehicleArrow from '../../public/assets/icons/vehicle-arrow';
import { useIsBoiWebsite } from "../Context/WebsiteType";

interface ILearnMoreButton {
  className?: string,
  onClick?: any,
  btnTxt?: string,
  blueBtn?: boolean,
  arrowColor?: string
}

export default function LearnMoreButton({ className, onClick, btnTxt = 'Learn More', blueBtn, arrowColor = '#1A1C1F' }: ILearnMoreButton) {

  const isBoiWebsite = useIsBoiWebsite();
  return (
    <div className={`${isBoiWebsite ? `${styles.vehicleButtonBoi} light-blue-button-boi` : `${styles.vehicleButton} blue-button`} ${className} ${blueBtn && styles.blueBtn}`} onClick={onClick}>
      <span>{ btnTxt }</span>
      <div><VehicleArrow fill={arrowColor} /></div>
    </div>
  )
}