/* eslint-disable @next/next/no-img-element */

import React from 'react';

import styles from './styles.module.scss'
import LoaderIcon from '../../public/assets/icons/loader.png'
import boiLoaderIcon from '../../public/assets/nevo-boi/loader.svg'
import { useIsBoiWebsite } from "../Context/WebsiteType";

interface ILoader {
  className?: string
} 

const Loader = ({ className }: ILoader) => {

  const isBoiWebsite = useIsBoiWebsite();

  return (
    <div className={`${styles.loaderHolder} ${className}`}>
      <img src={isBoiWebsite ? boiLoaderIcon.src : LoaderIcon.src} alt='Loading Spinner' />
    </div>
  )
};

export default Loader;