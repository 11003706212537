/* eslint-disable @next/next/no-img-element */
import React, { useEffect, useState } from 'react';
import Link from 'next/link';

import slugify from 'react-slugify';
import { formatCurrency } from '../../../../helper/vehicle_formatter';

import { event } from '../../../../lib/gtag';

import Loader from '../../../Loader';
import LearnMoreButton from '../../../LearnMoreButton';

import styles from './styles.module.scss';
import Backend from '../../../../utils/Backend';
import { formatNumber } from '../../../../helper/number_formatter';


interface IUsedVehicleCard {
  vehicle: any
}

const UsedVehicleCard = ({ vehicle } : IUsedVehicleCard) => {
  const [imageURL, setImageURL] = useState<string>()

  useEffect(() => {
    let image: string;

    if (vehicle.nevo) {
      let paintId: string | undefined;

      const matchColor = vehicle.color
      const colors = vehicle.nevo.colors;

      if (matchColor && colors && colors.length) {
        const match = colors.find((c : any) => c.name.toLowerCase().includes(matchColor.toLowerCase()))
        
        if (match) {
          paintId = match.code
        }
      }

      image = Backend.getImage(vehicle.nevo, "28", paintId, 800, vehicle.year)
    }
    else {
      image = vehicle.featured_image.original
    }

    // preloading image
    const img = new Image()
    img.src = image;

    setTimeout(() => {
      setImageURL(image)
    }, 1000)
  }, [vehicle])

  // destructuring vehicle
  const {
    id,
    model: {
      name: model,
      manufacturer: { name: make }
    },
    fuel, year, mileage_kms, price,
  } = vehicle; 

  const fixedPrice = price / 100;

  const baseURL = "/used-vehicle";
  const generatedURL = `${baseURL}/${slugify(make)}/${slugify(model)}/${id}`;

  return (
    <Link href={generatedURL} passHref>
      <a className={styles.refHolder}>
        <div className={styles.vehicleCard}>
          
          <div className={`${styles.imageHolder} ${!!!imageURL && styles.loading}`}>
            { !!imageURL 
              ? <div className={styles.vehicleImage} style={{ backgroundImage: `url(${imageURL})` }} />
              : <Loader />
            }
          </div>

          <div className={`${styles.vehicleDescription}`}>
            <p className={styles.vehicleType}>
              { fuel === 3 ? "All-Electric" : "Plug-in Hybrid" }
            </p>

            <h1 className={styles.vehicleTitle}>{ make } { model }</h1>

            <p className={styles.vehicleYear}>{ year }</p>
            
            <p className={styles.vehicleMileage}>
              { formatNumber(mileage_kms) } km
            </p>

            <p className={styles.vehiclePrice}>{ formatCurrency(fixedPrice) }</p>

            <LearnMoreButton 
              className={styles.learnMoreBtn}
              onClick={() => event("clickedOnLearnMore")} 
            />
          </div>
        </div>
      </a>
    </Link>
  )
};

export default UsedVehicleCard;