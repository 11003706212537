/* eslint-disable @next/next/no-img-element */

import React, { useEffect, useState } from 'react';
import Link from 'next/link';

import Backend from '../../../../../utils/Backend';
import slugify from 'react-slugify';
import { formatFromPrice, formatRange } from '../../../../../helper/vehicle_formatter';

import { event } from '../../../../../lib/gtag';

import LearnMoreButton from '../../../../LearnMoreButton';
import Loader from '../../../../Loader';

import styles from '../styles.module.scss';
import { useIsBoiWebsite } from "../../../../Context/WebsiteType";


interface IVehicleCard {
  vehicle: any,
  commercial?: boolean,
}

const VehicleCard = ({ vehicle, commercial } : IVehicleCard) => {
  const [imageURL, setImageURL] = useState("")

  const {
    id, make, model, 
    price, fuel_type, range,
    cargo_weight
  } = vehicle; 

  useEffect(() => {
    setImageURL(Backend.getImage(vehicle, "28"))
  }, [vehicle])

  const baseURL = commercial ? "/commercial-vehicle" : "/vehicle";
  const generatedURL = `${baseURL}/${slugify(make)}/${slugify(model)}/${id}`;
  const isBoiWebsite = useIsBoiWebsite();

  return (
    <Link href={generatedURL} passHref>
      <a className={`${isBoiWebsite ? styles.refHolderBoi : styles.refHolder}`}>
        <div className={`${isBoiWebsite ? styles.vehicleCardBoi : styles.vehicleCard}`}>
          <div className={`${isBoiWebsite ? styles.vehicleImageBoi : styles.vehicleImage}`} style={{ backgroundImage: `url(${imageURL})` }}>
            {
              imageURL === ""
              ? <Loader />
              : <>&nbsp;</>
            }
          </div>

          <div className={`${isBoiWebsite ? styles.vehicleDescriptionBoi : styles.vehicleDescription} ${commercial && styles.commercialVehicle}`}>
            <p className={`${isBoiWebsite ? styles.vehicleTypeBoi : styles.vehicleType}`}>
              {
                fuel_type === "BEV" ? "All-Electric" :
                fuel_type === "PHEV" ? "Plug-in Hybrid" : fuel_type
              }
            </p>

            <h1 className={`${isBoiWebsite ? styles.vehicleTitleBoi : styles.vehicleTitle}`}>{ make + " " + model }</h1>

            <p className={`${isBoiWebsite ? styles.vehicleRangeBoi : styles.vehicleRange}`}>
              { parseInt(range) > 0 ? `Up to ${range}` : '- ' }km Range {formatRange(fuel_type)}
            </p>
            
            { commercial && (
              <p className={`${isBoiWebsite ? styles.vehicleLoadBoi : styles.vehicleLoad}`}>
                { parseInt(cargo_weight) > 0 ? cargo_weight : '-' }kg Load Weight
              </p>
            )}
            
            <p className={`${isBoiWebsite ? styles.vehiclePriceBoi : styles.vehiclePrice}`}>{ formatFromPrice(price) }</p>

            <LearnMoreButton 
              className={`${isBoiWebsite ? styles.learnMoreBtnBoi : styles.learnMoreBtn}`}
              onClick={() => event("clickedOnLearnMore")} 
            />
          </div>
        </div>
      </a>
    </Link>
  )
};

export default VehicleCard;
