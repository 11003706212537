/* eslint-disable @next/next/no-img-element */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState, useRef } from 'react';
import { useRouter } from 'next/router';

import styles from './styles.module.scss';
import rightArrowIcon from '../../public/assets/nevo-boi/chevron-right.svg'; 
import seeAllIcon from '../../public/assets/icons/see-all.svg'; 


interface IPostsHolder {
  children: any;
  title: string;
  seeAllPathname: string;
}

const PostsHolder = ({ children, title, seeAllPathname } : IPostsHolder) => {
  const [holderHeight, setHolderHeight] = useState(0);
  const [overflowing, setOverflowing] = useState(true);

  const postsHolderRef = useRef<HTMLDivElement>(null);
  const carouselRef = useRef<HTMLDivElement>(null);
  const router = useRouter();

  useEffect(() => {
    const scrollAnimation = () => {
      // Appearing animation when you scrolled to the element
      if (postsHolderRef && postsHolderRef.current) {
        
        const position = postsHolderRef.current.getBoundingClientRect().top - window.innerHeight;
        
        if (position < -100) {
          if (!postsHolderRef.current.classList.contains(styles.postPreviewAnimation)) {
            postsHolderRef.current.classList.toggle(styles.postPreviewAnimation); 
          }
          window.removeEventListener('scroll', scrollAnimation);
        }
      }      
    }

    scrollAnimation();
    window.addEventListener('scroll', scrollAnimation);
    return () => window.removeEventListener('scroll', scrollAnimation);
  }, [carouselRef, carouselRef.current]);

  useEffect(() => {
    const adaptPage = () => {
      // Adapt post holder height and set overflowing
      if (carouselRef && carouselRef.current) {
        setHolderHeight(carouselRef.current.clientHeight);  
        setOverflowing(carouselRef.current.scrollWidth > carouselRef.current.clientWidth);
      }      
    }

    adaptPage();
    window.addEventListener('resize', adaptPage);
    return () => window.removeEventListener('resize', adaptPage);
  }, [carouselRef, carouselRef.current]);


  const onSeeAllClick = () => {
    router.push(`/all/${seeAllPathname}`);
  }

  const renderFadingCover = () => {
    if (overflowing) {
      return (
        <div className={`${styles.fadingCover}`}
          onClick={ onSeeAllClick }
          style={{ height: `${holderHeight}px` }}
        >
          <img className={styles.arrowIcon} src={rightArrowIcon.src} alt="scroll right arrow"/>
        </div>
      );
    }
  }

  const renderSeeAll = () => {
    if (overflowing) {
      return (
        <div className={styles.seeAll} onClick={onSeeAllClick}>
          <p>See all</p>
          <img src={seeAllIcon.src} alt="See all icon"/>
        </div>
      );
    }
  }

  return (
    <div className={styles.postPreview} ref={postsHolderRef}>
      <h1 className={styles.postsTitle}>{ title }</h1>
      
      {renderSeeAll()}

      <div className={styles.postsContainer}>
        
        <div className={styles.postsHolder} ref={carouselRef}>
          { children }
        </div>
        { renderFadingCover() }
      </div>
    </div>
    
  )
};

export default PostsHolder;
